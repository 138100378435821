import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'lib-form-step-controls',
  templateUrl: './form-step-controls.component.html',
  styleUrls: ['./form-step-controls.component.css']
})
export class FormStepControlsComponent implements OnInit {

  @Input() saving: boolean = false;
  @Input() parentForm: UntypedFormGroup;
  @Input() formGroup: UntypedFormGroup;
  @Output() saveRequested: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  save() {
    this.saveRequested.emit();
  }

}
