  <div class="container">
  <div fxFlex fxLayout="column" fxLayoutAlign="center center">
    <div id = "homeBanner" fxFlex fxLayout="column" fxLayoutAlign="center center">
      <div class="mat-display-1" id= "hometitle">{{ title }}</div>
      <h4 id ="homeOverview">{{ homeOverview }}</h4>
      <div id ="homeButton">
        <button *ngIf="!loggedIn" mat-button (click)="login()" class="nav-button" id ="homeLogin">
          <mat-icon class="nav-icon" inline="true" id="homeLogintitle">login</mat-icon> Login
        </button>
      </div>
    </div>
    <ng-container *ngIf="loading; then loadingDisplay else createOrDisplayApplication"></ng-container>
  </div>
</div>
<ng-template #loadingDisplay>
  <mat-spinner diameter="30"></mat-spinner>
</ng-template>

<ng-template #createOrDisplayApplication>
  <ng-container *ngIf="!failedLoad; else failureMessage">
    <mat-tab-group #tabs dynamicHeight mat-align-tabs="center" animationDuration="0ms"
      [(selectedIndex)]="selectedIndex">
      <mat-tab label="Introduction">
        <app-intro></app-intro>
      </mat-tab>
      <mat-tab *ngIf="loggedIn" label="Status">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-accordion class="status-info" multi>
            <ng-container *ifSome="application; else noApplication; let a">
              <mat-expansion-panel expanded disabled>
                <mat-expansion-panel-header>
                  <div fxFlex fxLayout="row" fxLayoutAlign="none center">
                    <mat-panel-title id = "overviewTitle">
                      Status:
                    </mat-panel-title>
                    <lib-status-indicator [statusName]="StatusName[a.applicationDetails.status]">
                    </lib-status-indicator>
                  </div>
                </mat-expansion-panel-header>
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                  <div class="status-message" [innerHTML]="StatusMessage[a.applicationDetails.status]"></div>
                  <div class="intro-text" *ngIf="a.applicationDetails.status === appStatus.Incomplete"
                    [innerHTML]="introText"></div>
                  <div class="intro-text">For more information, please see the <a href="javascript:void(0);"
                      (click)="openFAQs()">FAQ</a> tab.
                  </div>
                  <button *ngIf="a.applicationDetails.status === appStatus.Incomplete" mat-raised-button color="primary"
                    (click)="enterAppCreation(tabs)">
                    Resume Application
                  </button>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel expanded disabled *ngIf="a.applicationDetails.status === appStatus.Returned">
                <mat-expansion-panel-header>
                  <div fxFlex fxLayout="row" fxLayoutAlign="none center">
                    <mat-panel-title id = "overviewTitle">
                      Message from reviewer:
                    </mat-panel-title>
                  </div>
                </mat-expansion-panel-header>
                <div fxLayout="column" fxLayoutAlign="center center">
                  <mat-form-field class="review-message" appearance="fill">
                    <mat-label>{{reviewMessage.time | date:'d MMM, y, h:mm a'}}</mat-label>
                    <textarea matInput disabled cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"
                      [value]="reviewMessage.comment">
                </textarea>
                  </mat-form-field>
                  <button mat-raised-button color="primary" (click)="enterAppCreation(tabs)">
                    Revise Application
                  </button>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel *ngIf="showDetail">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Submission details:
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <lib-create-application class="submission-detail" [application]="application" readOnly="true"
                  [contactEmail]="b2cEmail" viewerMode="true"></lib-create-application>
              </mat-expansion-panel>
            </ng-container>
            <ng-container>

            </ng-container>
          </mat-accordion>
        </div>
      </mat-tab>
      <mat-tab *ngIf="inCreation && loggedIn" label="Application">
        <lib-create-application [application]="application" (submitted)="onSubmit($event, tabs)"
          [contactEmail]="b2cEmail" (saved)="onSave($event)" [createApplication]="createApplicationFunction">
        </lib-create-application>
      </mat-tab>
      <mat-tab label="FAQ">
        <app-faq></app-faq>
      </mat-tab>
    </mat-tab-group>

    <ng-template #noApplication>
      <mat-expansion-panel expanded disabled>
        <div class="not-started" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
          <div class="status-message">You haven't started an application yet.</div>
          <div class="intro-text" [innerHTML]="introText"></div>
          <div class="intro-text">For more information, please see the FAQ tab.</div>
          <button mat-raised-button color="primary" (click)="enterAppCreation(tabs)">Start Application</button>
        </div>
      </mat-expansion-panel>
    </ng-template>

  </ng-container>
</ng-template>

<ng-template #createApplication>
  <lib-create-application [application]="application" (submitted)="onSubmit($event)" (saved)="onSave($event)"
    [contactEmail]="b2cEmail" [createApplication]="createApplicationFunction"></lib-create-application>
</ng-template>

<ng-template #displayApplication>
</ng-template>

<ng-template #failureMessage>
  <div *ngIf="forbiddenLoad">This application was submitted on your behalf by an administrator. Please contact a member
    of the team for more details.</div>
  <div *ngIf="!forbiddenLoad">There was an error accessing your application. Please try again later.</div>
</ng-template>