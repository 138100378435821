import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENVIRONMENT, FileUpload } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  uploadURL: string;
  uploadByIdURL(id: string): string {
    return `${this.environment.backendURL}/applications/${id}/uploads`;
  }

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: any) {
    this.uploadURL = this.environment.backendURL + '/application/uploads'
  }

  upload(documentName: string, formData: FormData): Observable<FileUpload> {
    return this.http.post<FileUpload>(`${this.uploadURL}/${documentName}`, formData);
  }

  delete(documentName: string, documentId: string): Observable<any> {
    return this.http.delete(`${this.uploadURL}/${documentName}/${documentId}`);
  }

  uploadById(id: string, documentName: string, formData: FormData): Observable<FileUpload> {
    return this.http.post<FileUpload>(`${this.uploadByIdURL(id)}/${documentName}`, formData);
  }

  deleteById(id: string, documentName: string, documentId: string): Observable<any> {
    return this.http.delete(`${this.uploadByIdURL(id)}/${documentName}/${documentId}`);
  }
}