<div fxLayout="column" fxLayoutAlign="space-around center">
    <div class="button-container">
        <div class="spinner-container" *ngIf="spinning">
            <mat-spinner diameter="24"></mat-spinner>
        </div>
        <button mat-button [ngClass]="buttonType" [color]="color" [disabled]="spinning || disabled"
            (click)="onClick($event)">
            <ng-content></ng-content>
        </button>
    </div>
    <div *ngIf="!spinning && !disabled">{{ labelText }}</div>
</div>