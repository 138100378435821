import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MsalInterceptor } from '@azure/msal-angular';
import { AuthenticationService } from 'shared';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as O from 'fp-ts/es6/Option';

if (environment.production) {
  enableProdMode();
}

if (environment.disableMsal) {

  console.warn("Disabling MSAL service");
  function setLoggedIn() {
    AuthenticationService.prototype.isLoggedIn = () => true;
    AuthenticationService.prototype.getB2CEmail = () => O.some("test@example.com");
  }
  function setLoggedOut() {
    AuthenticationService.prototype.isLoggedIn = () => false;
    AuthenticationService.prototype.getB2CEmail = () => O.none;
  }
  AuthenticationService.prototype.login = () => {
    setLoggedIn()
  }
  AuthenticationService.prototype.logout = () => {
    setLoggedOut()
  }

  setLoggedOut()

  MsalInterceptor.prototype.intercept = (req, next) => {
    const access = localStorage.getItem('access_token');
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${access}`
      }
    });
    return next.handle(req);
  };
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
