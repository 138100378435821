import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'lib-button-spinner',
  templateUrl: './button-spinner.component.html',
  styleUrls: ['./button-spinner.component.css']
})
export class ButtonSpinnerComponent implements OnInit {

  @Input() spinning: boolean;
  @Input() disabled: boolean;
  @Input() buttonType: string = "mat-button";
  @Input() color: string;
  @Input() labelText: string;
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(event: any): void {
    this.buttonClick.emit(event);
  }

}
