<div class="array-header" *ngIf="formArray.length === 0 && !readOnly" fxLayout="row" fxLayoutAlign="none center" fxLayoutGap="10px">
  <div class="form-label" fxFlex>{{label}}:</div>
  <button mat-icon-button color="secondary" type="button" (click)='addItem()'>
    <mat-icon class="array-input-button-icon">add</mat-icon>
  </button>
</div>
<div class="array-input" [formGroup]="formGroup">
  <div [formArrayName]="formArrayName" fxLayout="column">
    <div *ngFor="let item of formArray.value; let i = index; trackBy:trackByFn">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
        <div fxFlex>
          <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: i  }">
          </ng-container>
        </div>
        <div class="array-input-buttons" *ngIf="(i === formArray.value.length - 1) && !readOnly">
          <button mat-icon-button  type="button"  color="secondary" (click)='removeItem(i)'>
            <mat-icon class="array-input-button-icon">delete</mat-icon>
          </button>
          <button type="button" *ngIf="!maxArraySizeReached()" mat-icon-button color="secondary" (click)='addItem()' [disabled]="!formArray.valid">
            <mat-icon class="array-input-button-icon">add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
