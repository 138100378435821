<div fxLayout="column" fxLayoutAlign="center center">
  <mat-accordion class="status-info" multi>
    <ng-container>
      <mat-expansion-panel expanded disabled class="introPanel">
        <mat-expansion-panel-header class ="overviewHeader" >
          <div fxFlex fxLayout="row" fxLayoutAlign="none center">
            <mat-panel-title  >
              Overview:
            </mat-panel-title>
          </div>
        </mat-expansion-panel-header>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
          <div class="intro-text">
            <ng-template [cdkPortalOutlet]="overviewText"></ng-template>
          </div>
        </div>
      </mat-expansion-panel>
      <div>
      <mat-expansion-panel expanded disabled id="overviewPanel" *ngIf="false">
        <mat-expansion-panel-header>
          <div fxFlex fxLayout="row" fxLayoutAlign="none center" >
            <mat-panel-title id ="overviewTitle">
              About this process
            </mat-panel-title>
          </div>
        </mat-expansion-panel-header>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
          <div class="intro-text" >
            <ng-template [cdkPortalOutlet]="aboutThisProcessText"></ng-template>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div>
      <mat-expansion-panel expanded disabled id = "importantTextPanel"  *ngIf="false">
        <mat-expansion-panel-header>
          <div fxFlex fxLayout="row" fxLayoutAlign="none center">
            <mat-panel-title id ="overviewTitle">
              Important Notes:
            </mat-panel-title>
          </div>
        </mat-expansion-panel-header>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
          <div class="intro-text">
            <ng-template [cdkPortalOutlet]="importantNotesText"></ng-template>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    </ng-container>
  </mat-accordion>
</div>