import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TabSelectionService {
    _selection: Subject<Selection>;
    constructor() {
        this._selection = new Subject<Selection>();
    }

    get getSelectionSubject(): Observable<Selection> {
        return this._selection.asObservable();
    }

    setSelection(selection: Selection) {
        this._selection.next(selection);
    }
}

export enum Selection {
    FAQ
}