import { ComponentPortal } from '@angular/cdk/portal';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { TextStrings } from 'shared';
import { LoginStatusService } from '../loginstatus.service';
import { Action, NavbarActionsService } from '../navbaractions.service';
import { Selection, TabSelectionService } from '../tabselection.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class IntroComponent implements OnInit, AfterViewChecked, AfterViewInit {

  overviewText: ComponentPortal<ComponentOverviewTextPortal>;
  aboutThisProcessText: ComponentPortal<ComponentAboutThisProcessTextPortal>;
  importantNotesText: ComponentPortal<ComponentImportantNotesTextPortal>;

  _loggedIn: boolean;
  get loggedIn(): boolean {
    return this._loggedIn;
  }
  set loggedIn(value: boolean) {
    this._loggedIn = value;
    this.removeListeners()
    this.addListeners()
  }

  constructor(private navbarService: NavbarActionsService, private loginService: LoginStatusService, private tabService: TabSelectionService, private elementRef: ElementRef) { }

  ngAfterViewInit(): void {
    if (this.loggedIn) {
      (<HTMLElement>this.elementRef.nativeElement).querySelectorAll('p.if-not-logged-in').forEach(e => {
        (e as HTMLElement).style.display = "none"
      })
    } else {
      (<HTMLElement>this.elementRef.nativeElement).querySelectorAll('p.if-logged-in').forEach(e => {
        (e as HTMLElement).style.display = "none"
      })
    }

  }

  ngOnInit(): void {
    this.overviewText = new ComponentPortal(ComponentOverviewTextPortal);
    this.aboutThisProcessText = new ComponentPortal(ComponentAboutThisProcessTextPortal);
    this.importantNotesText = new ComponentPortal(ComponentImportantNotesTextPortal);
    this.loginService.isLoggedIn.subscribe(b => {
      this.loggedIn = b
    })
    this.removeListeners()
    this.addListeners()
  }

  ngAfterViewChecked() {
    this.removeListeners()
    this.addListeners()
  }

  addListeners() {
    if (this.loggedIn != null) {
      (<HTMLElement>this.elementRef.nativeElement).querySelectorAll('a.open-support').forEach(el => {
        el.addEventListener('click', this.raiseSupport.bind(this))
        el.setAttribute("href", "javascript:void(0)")
      });
      (<HTMLElement>this.elementRef.nativeElement).querySelectorAll('a.open-login').forEach(el => {
        el.addEventListener('click', this.login.bind(this))
        el.setAttribute("href", "javascript:void(0)")
      })
    }
    (<HTMLElement>this.elementRef.nativeElement).querySelectorAll('a.open-faqs').forEach(el => {
      el.addEventListener('click', this.openFAQs.bind(this))
      el.setAttribute("href", "javascript:void(0)")
    })
  }

  removeListeners() {
    (<HTMLElement>this.elementRef.nativeElement).querySelectorAll('a.open-support').forEach(el => {
      el.removeAllListeners("click")
    });
    (<HTMLElement>this.elementRef.nativeElement).querySelectorAll('a.open-login').forEach(el => {
      el.removeAllListeners("click")
    });
    (<HTMLElement>this.elementRef.nativeElement).querySelectorAll('a.open-faqs').forEach(el => {
      el.removeAllListeners("click")
    })
  }

  login() {
    this.navbarService.setAction(Action.Login)
  }

  openFAQs() {
    this.tabService.setSelection(Selection.FAQ)
  }

  raiseSupport() {
    if (this.loggedIn) {
      this.navbarService.setAction(Action.ContactUs)
    } else {
      this.navbarService.setAction(Action.Login)
    }
  }

}

@Component({
  selector: 'component-overview-text-portal',
  template: TextStrings.overviewText
})
export class ComponentOverviewTextPortal { }

@Component({
  selector: 'component-about-this-process-text-portal',
  template: TextStrings.aboutThisProcessText
})
export class ComponentAboutThisProcessTextPortal { }

@Component({
  selector: 'component-important-notes-text-portal',
  template: TextStrings.importantNotesText
})
export class ComponentImportantNotesTextPortal { }