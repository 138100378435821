import { Inject, Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, AuthError } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from './models';
import * as O from 'fp-ts/es6/Option';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private msalService: MsalService, @Inject(ENVIRONMENT) private environment: any) { }

  logout(): void {
    this.msalService.logout();
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  login(): void {
    this.msalService.loginRedirect({ scopes: this.environment.backendScopes });
  }

  resetPassword(): void {
    this.msalService.loginRedirect({ authority: this.environment.passwordResetAuthority, scopes: this.environment.backendScopes })
  }

  getB2CEmail(): O.Option<string> {
    let emails: string[] = [];
    this.msalService.instance.getAllAccounts().forEach(
      a => {
        try {
          (a.idTokenClaims["emails"] as string[]).forEach(e => emails.push(e))
        } catch (error) {

        }
      });
    if (emails.length == 0) {
      return O.none;
    } else {
      return O.some(emails[0])
    }
  }

  handleRedirect(): Observable<AuthenticationResult> {
    return this.msalService.handleRedirectObservable();
  }

  processAuthError(err: AuthError): void {
    if (err.errorMessage.indexOf("AADB2C90118") > -1) {
      this.resetPassword();
    } else if (err.errorMessage.indexOf("AADB2C90091") > -1) {
      //Password reset cancelled, redirect user to login
      this.login();
    }
    else {
      console.error(err);
    }
  }
}
