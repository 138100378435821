<div fxLayout="column">
  <mat-form-field>
    <mat-label>{{label}}</mat-label>
    <input matInput type="tel" [formControl]="fc" maxLength="20">
    <mat-error *ngIf="fc?.hasError('pattern')">
      Please enter a valid phone number.
    </mat-error>
    <mat-icon *ngIf="!array" matSuffix inline="true">phone</mat-icon>
  </mat-form-field>
</div>
