export const environment = { production: "",
disableMsal: false,
clientId: "0c899ce4-1c3e-44e8-b44b-2c57fbe06a07",
redirectBase: "https://www.remediation.nbs.co.nz",
authorityBase: "nznbsprodcrp002.b2clogin.com",
loginAuthority: "https://nznbsprodcrp002.b2clogin.com/nznbsprodcrp002.onmicrosoft.com/B2C_1_sign_up_sign_in",
passwordResetAuthority: "https://nznbsprodcrp002.b2clogin.com/nznbsprodcrp002.onmicrosoft.com/B2C_1_password_reset",
backendURL: "https://www.remediation.nbs.co.nz/api",
backendScopes: ["https://nznbsprodcrp002.onmicrosoft.com/0c899ce4-1c3e-44e8-b44b-2c57fbe06a07/Backend.Access"]
 };
