<form [formGroup]="formGroup">
  <div class="container" fxLayout="column" fxLayoutGap="40px">
    <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px"  *ngIf="adminMode">
      <div fxFlex>
        <div class="container" fxLayout="column" fxLayoutAlign="center none">
          <mat-accordion multi displayMode="flat">
            <mat-expansion-panel disabled expanded>
              <div class="deceased-box">
                <mat-checkbox formControlName="isDeceasedEstate">I am applying on behalf of a deceased estate
                </mat-checkbox>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="isExpanded" (opened)="isExpanded = true" (closed)="isExpanded = false"
              *ngIf="!readOnly">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="container" fxLayout="row" fxLayoutGap="10px">
                    <div fxFlex="1 1 25px" fxLayout="column" fxLayoutAlign="center center">
                      <mat-icon>help</mat-icon>
                    </div>
                    <span>How do I apply on behalf of a deceased estate?</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div [innerHTML]="deceasedEstateInfo"></div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
    <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <div fxFlex>
        <div class="container" fxLayout="column" fxLayoutAlign="center none">
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput formControlName="givenNames" maxlength="40">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Surname</mat-label>
            <input matInput formControlName="surname" maxlength="40">
          </mat-form-field>
        </div>
      </div>
      <div fxFlex>
        <div class="container" fxLayout="column" fxLayoutAlign="center none">
          <lib-array-input [itemTemplate]="previousName" formArrayName="previousNames" [formGroup]="formGroup"
            [readOnly]="readOnly" label="Previous Legal Names" [emptyArrayElement]="nameControl">
            <ng-template #previousName let-i>
              <mat-form-field fxFlex>
                <mat-label>Previous Name</mat-label>
                <input matInput [formControlName]="i" maxlength="80">
              </mat-form-field>
            </ng-template>
          </lib-array-input>
          <mat-form-field dateFormat>
            <mat-label>Date of Birth</mat-label>
            <input matInput [matDatepicker]="picker" [min]="minDOB" [max]="maxDOB" formControlName="dateOfBirth"
              placeholder="DD/MM/YYYY" (click)="picker.open()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker startView="multi-year"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</form>