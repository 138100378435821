<div fxLayout="column">
  <mat-form-field>
    <mat-label>{{label}}</mat-label>
    <input type="email" matInput [formControl]="fc" placeholder="Ex. pat@example.com">
    <mat-icon *ngIf="!array" matSuffix inline="true">email</mat-icon>
    <mat-error *ngIf="fc.hasError('email')">
      Please enter a valid email address
    </mat-error>
  </mat-form-field>
</div>
