import { ComponentPortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import * as O from 'fp-ts/es6/Option';
import { Option, some } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/pipeable';
import { Observable, Subscription } from 'rxjs';
import { TextStrings } from '../../modular/text-strings';
import { ApplicationRequest, ApplicationStatus, BaseAdditionalDetails, BaseApplicationResponse } from '../models';
import { formArrayMinMaxLength, phoneValidator, validateBankAccount, validateClientId, allowedNameCharactersValidator, allowedTextCharactersValidator, allowedExtendedTextCharactersValidator } from '../validators';

const bankPattern: string = "^(\\d{2})-(\\d{4})-(\\d{7})-(\\d{2,3})$"

@Component({
  selector: 'lib-create-application',
  templateUrl: './create-application.component.html',
  styleUrls: ['./create-application.component.css'],
  inputs: ["application"]
})
export class CreateApplicationComponent implements OnInit, AfterViewInit {

  privacyDisclaimer: ComponentPortal<ComponentPrivacyDisclaimerPortal>;

  @Input() application: Option<BaseApplicationResponse<BaseAdditionalDetails>>

  //Needed to switch the supporting docs into upload by id (i.e. admin) mode - should be none unless it's admin mode
  @Input() appId: Option<string> = O.none;

  @Input()
  get readOnly(): boolean { return this._readOnly }
  set readOnly(readOnly: boolean) {
    this._readOnly = readOnly;
    if (typeof (this.applicationForm) != "undefined") {
      if (!readOnly) {
        this.applicationForm.enable({ emitEvent: false });
        if (this.viewerMode) {
          this.bankAndTaxDetailsForm.get('clientId').disable();
        }
        this.supportingDocumentsForm.enable({ emitEvent: false });
      } else {
        this.applicationForm.disable({ emitEvent: false });
        this.supportingDocumentsForm.disable({ emitEvent: false });
      }
    }
  }
  private _readOnly: boolean = false;

  @Input() viewerMode: boolean = false;
  @Input() adminMode: boolean = false;
  @Input() contactEmail: string;
  @Input() createApplication: (app: ApplicationRequest) => Observable<BaseApplicationResponse<BaseAdditionalDetails>>;

  @Output() submitted: EventEmitter<BaseApplicationResponse<BaseAdditionalDetails>> = new EventEmitter<BaseApplicationResponse<BaseAdditionalDetails>>();
  @Output() saved: EventEmitter<Option<BaseApplicationResponse<BaseAdditionalDetails>>> = new EventEmitter<Option<BaseApplicationResponse<BaseAdditionalDetails>>>();

  @ViewChild(MatStepper) stepper: MatStepper;

  applicationForm: UntypedFormGroup;
  personalDetailsForm: UntypedFormGroup;
  contactDetailsForm: UntypedFormGroup;
  bankAndTaxDetailsForm: UntypedFormGroup;
  historicDetailsForm: UntypedFormGroup;
  supportingDocumentsForm: UntypedFormGroup;
  saving: boolean = false;
  submitting: boolean = false;

  addressControl: () => AbstractControl;
  emailControl: () => AbstractControl;
  phoneControl: () => AbstractControl;
  nameControl: () => AbstractControl;
  bankControl: () => AbstractControl;
  supportingDocumentControl: () => AbstractControl;


  constructor(private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar) {

  }
  ngAfterViewInit(): void {
    if (this.viewerMode) {
      //Hack to stop the edit symbol showing up when navigating between steps in viewer mode
      this.stepper.steps.forEach(_ => _.completed = false)
    }
  }

  submit(): void {
    if (this.applicationForm.valid) {
      this.submitting = true;
      this.createApplication({
        status: ApplicationStatus.Submitted,
        formDetails: this.applicationForm.value
      }).subscribe(a => {
        this.submitting = false;
        this.submitted.emit(a);
      },
        error => {
          console.error(error);
          this.submitting = false;
          this.snackBar.open("An error occurred whilst submitting your application. Please try again later.", "Dismiss")
        }
      )
    }
    else {
      //Shouldn't reach here, but this is a safeguard
      this.snackBar.open("There were errors in your application. Please check and try again.", "Dismiss");
    }
  }

  selectionChanged(): void {
    if (!this.viewerMode && !this.readOnly) {
      this.save();
    }
  }

  save(): void {
    if (this.applicationForm.dirty) {
      this.setValidators(false);
      if (this.applicationForm.valid) {
        this.saving = true;
        this.createApplication({
          status: pipe(this.application,
            O.map(_ => _.applicationDetails.status),
            O.getOrElse(() => ApplicationStatus.Incomplete)
          ),
          formDetails: this.applicationForm.getRawValue()
        }).subscribe(a => {
          this.saving = false;
          this.application = some(a);
          this.snackBar.open("Your application has been saved as draft.", "Dismiss");
          this.applicationForm.markAsPristine();
          this.saved.emit(some(a));
          this.setValidators(true);
        },
          error => {
            console.error(error);
            this.snackBar.open("An error occurred whilst saving. Please try again later.", "Dismiss")
            this.saving = false;
            this.setValidators(true);
          }
        )
      }
      else {
        this.snackBar.open("Unable to save progress due to invalid input.", "Dismiss");
        this.setValidators(true);
      }
    } else {
      //Needed for admin app detail to work. In a way the state has been saved (just nothing actually changed).
      this.saved.emit(this.application);
    }
  }

  //Configure validators here. If a field is required but needs no additional validation, it does not need to be configured here.
  //If a field is not required (e.g. previousNames), it should be explicitly declared here.
  private getValidators(fieldName: string, includeRequired: boolean, formPart: UntypedFormGroup): ValidatorFn[] {
    const requiredValidators = includeRequired ? [Validators.required] : []

    switch (fieldName) {
      case "givenNames":
        return [allowedNameCharactersValidator].concat(requiredValidators);
      case "surname":
        return [allowedNameCharactersValidator].concat(requiredValidators);
      case "phoneNumber":
        return [phoneValidator].concat(requiredValidators);
      case "clientId":
        return [validateClientId].concat(requiredValidators);
      case "previousNames":
        return [allowedNameCharactersValidator];
      case "previousBankAccounts":
        return [];
      case "previousHomePhoneNumbers":
        return [];
      case "previousMobilePhoneNumbers":
        return [];
      case "previousAddresses":
        return [];
      case "previousEmailAddresses":
        return [];
      case "isOverseasAccount":
        return [];
      case "isDeceasedEstate":
        return [];
      case "suburb":
        return [allowedTextCharactersValidator];
      case "address":
        return [allowedTextCharactersValidator].concat(requiredValidators);
      case "city":
        return [allowedTextCharactersValidator].concat(requiredValidators);
      case "postcode":
        return [allowedTextCharactersValidator].concat(requiredValidators);
      case "overseasAccountDetails":
        {
          let isOverseasBank: boolean | null = formPart.get("isOverseasAccount").value;
          if (isOverseasBank != null && isOverseasBank == true) {
            return [allowedExtendedTextCharactersValidator].concat(requiredValidators);
          } else {
            return []
          }
        }
      case "bankAccountNumber":
        {
          let isOverseasBank: boolean | null = formPart.get("isOverseasAccount").value;
          if (isOverseasBank == null || isOverseasBank == false) {
            return [Validators.pattern(bankPattern), validateBankAccount].concat(requiredValidators);
          } else {
            return []
          }
        }
      default:
        return requiredValidators;
    }
  };

  private isOverseasAccountChangeSubscription: Subscription = null;

  private setValidators(includeRequired: boolean): void {
    if (includeRequired && this.isOverseasAccountChangeSubscription == null) {
      this.isOverseasAccountChangeSubscription = this.bankAndTaxDetailsForm.get('isOverseasAccount').valueChanges.subscribe(v => {
        ["bankAccountNumber", "overseasAccountDetails"].forEach(f => {
          this.bankAndTaxDetailsForm.get(f).setValidators(this.getValidators(f, true, this.bankAndTaxDetailsForm));
          this.bankAndTaxDetailsForm.get(f).updateValueAndValidity();
        })
      })
    } else if (!includeRequired && this.isOverseasAccountChangeSubscription != null) {
      this.isOverseasAccountChangeSubscription.unsubscribe()
      this.isOverseasAccountChangeSubscription = null;
    }
    Object.keys(this.applicationForm.controls).forEach(formPartKey => {
      const formPart = this.applicationForm.get(formPartKey) as UntypedFormGroup;
      this.setValidatorsOnFormGroup(formPart, includeRequired);
    })
  }

  private setValidatorsOnFormGroup(formPart: UntypedFormGroup, includeRequired: boolean): void {
    Object.keys(formPart.controls).forEach(fieldName => {
      if (formPart.get(fieldName) instanceof UntypedFormGroup) {
        this.setValidatorsOnFormGroup(formPart.get(fieldName) as UntypedFormGroup, includeRequired)
      }
      formPart.get(fieldName).setValidators(this.getValidators(fieldName, includeRequired, formPart));
      formPart.get(fieldName).updateValueAndValidity();
    })
  }

  initArrayField(parent: string, fieldName: string, control: () => AbstractControl = () => this.formBuilder.control(null)): UntypedFormArray {
    const formArray = this.formBuilder.array([]);
    const initValue = pipe(
      this.application,
      O.map(a => {
        const arrayVals = a.applicationDetails.formDetails[parent][fieldName] as [];
        arrayVals.forEach(v => formArray.push(control()))
      })
    )
    return formArray;
  }

  initSupportingDocArrayField(documentType: string, validator: ValidatorFn, control: () => AbstractControl = () => this.formBuilder.control(null)): UntypedFormArray {
    const formArray = this.formBuilder.array([], validator);
    const initValue = pipe(
      this.application,
      O.chain(a => O.fromNullable(a.additionalDetails)),
      O.chain(a => O.fromNullable(a.supportingDocuments)),
      O.map(a => {
        const arrayVals = O.getOrElse(() => [])(O.fromNullable(a[documentType] as []));
        arrayVals.forEach(v => formArray.push(control()))
      })
    )
    return formArray;
  }

  ngOnInit(): void {

    this.privacyDisclaimer = new ComponentPortal(ComponentPrivacyDisclaimerPortal);

    this.addressControl = () => this.formBuilder.group({
      address: [null, [Validators.required, allowedTextCharactersValidator]],
      suburb: [null, allowedTextCharactersValidator],
      city: [null, [Validators.required, allowedTextCharactersValidator]],
      postcode: [null, [Validators.required, allowedTextCharactersValidator]],
      country: ['New Zealand', Validators.required]
    })
    //Do i need to add validator in here
    this.emailControl = () => this.formBuilder.control(null, [Validators.required, Validators.email])

    this.phoneControl = () => this.formBuilder.control(null, [Validators.required, phoneValidator]);

    this.nameControl = () => this.formBuilder.control(null, [Validators.required, allowedNameCharactersValidator]);

    this.bankControl = () => this.formBuilder.control(null, [Validators.required, validateBankAccount, Validators.pattern(bankPattern)]);

    this.supportingDocumentControl = () => this.formBuilder.control(null, []);

    this.personalDetailsForm = this.formBuilder.group({
      isDeceasedEstate: null,
      givenNames: null,
      surname: null,
      previousNames: this.initArrayField('personalDetails', 'previousNames', this.nameControl),
      dateOfBirth: null
    })
    this.contactDetailsForm = this.formBuilder.group({
      phoneNumber: null,
      contactAddress: this.addressControl()
    })
    this.bankAndTaxDetailsForm = this.formBuilder.group({
      bankAccountNumber: null,
      overseasAccountDetails: null,
      clientId: null,
      isOverseasAccount: null
    });
    this.historicDetailsForm = this.formBuilder.group({
      previousHomePhoneNumbers: this.initArrayField('historicDetails', 'previousHomePhoneNumbers', this.phoneControl),
      previousMobilePhoneNumbers: this.initArrayField('historicDetails', 'previousMobilePhoneNumbers', this.phoneControl),
      previousEmailAddresses: this.initArrayField('historicDetails', 'previousEmailAddresses', this.emailControl),
      previousBankAccounts: this.initArrayField('historicDetails', 'previousBankAccounts', this.bankControl),
      previousAddresses: this.initArrayField('historicDetails', 'previousAddresses', this.addressControl)
    });

    this.applicationForm = this.formBuilder.group({
      personalDetails: this.personalDetailsForm,
      contactDetails: this.contactDetailsForm,
      bankAndTaxDetails: this.bankAndTaxDetailsForm,
      historicDetails: this.historicDetailsForm
    })

    this.supportingDocumentsForm = this.formBuilder.group({
      proofOfIdentity: this.initSupportingDocArrayField("proofOfIdentity", formArrayMinMaxLength(1, 5), this.supportingDocumentControl),
      proofOfBankAccount: this.initSupportingDocArrayField("proofOfBankAccount", formArrayMinMaxLength(1, 5), this.supportingDocumentControl),
    })

    this.setValidators(true);

    pipe(
      this.application,
      O.map(a => this.applicationForm.patchValue(a.applicationDetails.formDetails))
    );

    pipe(
      this.application,
      O.chain(a => O.fromNullable(a.additionalDetails.supportingDocuments)),
      O.map(docs => {
        ["proofOfIdentity", "proofOfBankAccount"].forEach(field => {
          docs[field] = O.getOrElse(() => [])(O.fromNullable(docs[field]))
        })
        this.supportingDocumentsForm.patchValue(docs);
      })
    )

    if (this.readOnly) {
      this.applicationForm.disable({ emitEvent: false });
      this.supportingDocumentsForm.disable({ emitEvent: false });
    }
  }
}


@Component({
  selector: 'component-privacy-disclaimer-portal',
  template: TextStrings.submitPrivacyDisclaimerText
})
export class ComponentPrivacyDisclaimerPortal { }