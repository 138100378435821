import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NavbarActionsService {
    _action: Subject<Action>;
    constructor() {
        this._action = new Subject<Action>();

    }

    get getActionSubject(): Observable<Action> {
        return this._action.asObservable();
    }

    setAction(action: Action) {
        this._action.next(action);
    }
}

export enum Action {
    Login,
    Logout,
    ContactUs
}