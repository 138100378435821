/*
 * Public API Surface of shared
 */

export * from './lib/shared.module';
export * from './lib/models';
export * from './lib/models.spec';
export * from './lib/create-application/create-application.component';
export * from './lib/create-application/bank-tax-details/bank-tax-details.component';
export * from './lib/create-application/contact-details/contact-details.component';
export * from './lib/create-application/form-step-controls/form-step-controls.component';
export * from './lib/create-application/personal-details/personal-details.component';
export * from './lib/create-application/supporting-documents/supporting-documents.component';
export * from './lib/create-application/upload-file/upload-file.component';
export * from './lib/create-application/form-components/address/address.component';
export * from './lib/create-application/form-components/array-input/array-input.component';
export * from './lib/create-application/form-components/bank-account/bank-account.component';
export * from './lib/create-application/form-components/email-address/email-address.component';
export * from './lib/create-application/form-components/phone-number/phone-number.component';
export * from './lib/button-spinner/button-spinner.component';
export * from './lib/page-not-found/page-not-found.component'
export * from './lib/authentication.service';
export * from './lib/status-indicator/status-indicator.component';
export * from './lib/create-application/upload-file/upload.service'
export * from './modular/text-strings'
export * from './lib/validators'
export * from './material-file-input/model/file-input.model'

// export * from './ngx-fp-ts/if-either';
// export * from './ngx-fp-ts/if-option';
// export * from './ngx-fp-ts/ngx-fp-ts.module';
