import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FileUpload } from '../../models';
import * as O from 'fp-ts/es6/Option';
import { TextStrings } from '../../../modular/text-strings';
@Component({
  selector: 'lib-supporting-documents',
  templateUrl: './supporting-documents.component.html',
  styleUrls: ['./supporting-documents.component.css']
})
export class SupportingDocumentsComponent implements OnInit {
  @Input() emptyArrayElement: () => AbstractControl = () => new UntypedFormControl();
  @Input() formGroup: UntypedFormGroup;

  private hasInit: boolean = false;

  @Input()
  get editable(): boolean { return this._editable }
  set editable(editable: boolean) {
    this._editable = editable;
    if (editable && this.hasInit) {
      this.initFields();
      this.optionallyAddAllFields();
    }
  }
  private _editable: boolean = true;

  @Input() appId: O.Option<string> = O.none;
  proofOfIdentity: UntypedFormArray;
  proofOfBankAccount: UntypedFormArray;
  step = 0;
  maxUploads = {
    proofOfIdentity: 4,
    proofOfBankAccount: 4,
  };
  hints = TextStrings.supportingDocumentsHints;

  some(hint: string): O.Option<string> {
    return O.some(hint);
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  constructor() { }

  ngOnInit(): void {
    this.initFields();
    this.optionallyAddAllFields();
    this.hasInit = true;
  }

  initFields(): void {
    ["proofOfIdentity", "proofOfBankAccount"].forEach(fieldName => {
        this[fieldName] = this.formGroup.get(fieldName) as UntypedFormArray;
    })
  }

  optionallyAddAllFields(){
    ["proofOfIdentity", "proofOfBankAccount"].forEach(fieldName => {
        this.optionallyAddField(this[fieldName], fieldName);
    })
  }

  onUpload(fieldNameAndUpload: [string, FileUpload], form: UntypedFormArray, index: number): void {
    form.at(index).setValue(fieldNameAndUpload[1])
    this.optionallyAddField(form, fieldNameAndUpload[0]);
  }

  onDelete(fieldName: string, form: UntypedFormArray, index: number): void {
    form.removeAt(index);
    this.optionallyAddField(form, fieldName);
  }

  optionallyAddField(form: UntypedFormArray, fieldName: string): void {
    if (this.editable){
      let allSet = true;
      for (let i = 0; i < form.length; i++){
        if (O.isNone(O.fromNullable(form.at(i).value))) allSet = false
      }
      if(allSet && form.length < this.maxUploads[fieldName]) form.push(this.emptyArrayElement())
    }
  }

}
