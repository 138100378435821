<form [formGroup]="formGroup">
  <div class="container" fxLayout="column" fxLayoutAlign="center none">
    <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <div fxFlex>
        <div class="container" fxLayout="column" fxLayoutAlign="center none">
          <lib-phone-number [fc]="formGroup.get('phoneNumber')"></lib-phone-number>

          <div fxLayout="column">
            <mat-form-field>
              <mat-label>Email address</mat-label>
              <input type="email" matInput disabled placeholder="Ex. pat@example.com" value="{{contactEmail}}">
              <mat-icon matSuffix inline="true">email</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxFlex>
        <div class="container" fxLayout="column" fxLayoutAlign="center none">
          <lib-address [formGroup]="addressForm"></lib-address>
        </div>
      </div>
    </div>
  </div>
</form>