 <!-- level 01 -->
 <div fxLayout="column" fxLayoutAlign="center center">
 <mat-accordion class="faq" multi *ngFor="let q of faqsdict">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title><div [innerHTML]="q.key"></div> </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-accordion> 
      <ng-container *ngFor="let f of q.value; last as isLast">
      <mat-expansion-panel  class="mat-elevation-z0">
        <mat-expansion-panel-header class="question">
          <mat-panel-title><div [innerHTML]="f.question"></div></mat-panel-title>
        </mat-expansion-panel-header>
        <div [innerHTML]="f.answer"></div>
      </mat-expansion-panel>
      <mat-divider *ngIf="!isLast"></mat-divider>
    </ng-container>
    </mat-accordion>
  </mat-expansion-panel>
</mat-accordion>
</div>