import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Option, some } from 'fp-ts/es6/Option';
import { Observable } from 'rxjs';
import { ApplicationRequest, ApplicationStatus, AuthenticationService, Comment, CreateApplicationComponent, TextStrings, TTMApplicationResponse } from 'shared';
import { ApplicationService } from '../application.service';
import { StatusMessage, StatusName } from '../ttm-models';
import * as O from 'fp-ts/es6/Option'
import { pipe } from 'fp-ts/es6/function'
import { LoginStatusService } from '../loginstatus.service';
import { Selection, TabSelectionService } from '../tabselection.service';
import { Action, NavbarActionsService } from '../navbaractions.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  @ViewChild(CreateApplicationComponent) createAppComponent: CreateApplicationComponent;

  application: Option<TTMApplicationResponse>;
  loading: boolean;
  inCreation: boolean;
  appStatus = ApplicationStatus;
  failedLoad: boolean;
  forbiddenLoad: boolean;
  reviewMessage: Comment;
  b2cEmail: string;
  StatusMessage = StatusMessage;
  StatusName = StatusName;
  selectedIndex: number;
  title: string;
  homeOverview: string;

  _loggedIn: boolean;
  get loggedIn(): boolean {
    return this._loggedIn;
  }
  set loggedIn(value: boolean) {
    this.loading = true
    if (value) {
      this.setLoggedIn()
    } else {
      this.setLoggedOut()
    }
    this._loggedIn = value;
  }

  introText: string = `<p>To submit an application, we will need you to provide your current details,
  as well as any details we may have from your time working with us. It is important that you carefully
  review your details before submitting them as the information you provide will be compared against our
  client records to confirm your identity.</p>
<p>Once your application has been submitted, you cannot withdraw it.</p>`

  createApplicationFunction: (app: ApplicationRequest) => Observable<TTMApplicationResponse>;

  constructor(private applicationService: ApplicationService, private authService: AuthenticationService, private loginService: LoginStatusService, private tabService: TabSelectionService, private navbarService: NavbarActionsService) {

  }

  ngOnInit(): void {
    this.loading = true;
    this.application = O.none;
    this.inCreation = false;
    this.failedLoad = false;
    this.forbiddenLoad = false;
    this.loginService.isLoggedIn.subscribe(b => {
      this.loggedIn = b
    })
    this.tabService.getSelectionSubject.subscribe(t => {
      if (t == Selection.FAQ) {
        if (!this.loggedIn) {
          this.selectedIndex = 1
        } else if (this.inCreation) {
          this.selectedIndex = 3
        } else {
          this.selectedIndex = 2
        }
      }
    })
    this.introText = TextStrings.applicationIntroText;
  }

  openFAQs() {
    this.tabService.setSelection(Selection.FAQ)
  }

  setLoggedIn(): void {
    this.title = "Your Application"
    this.selectedIndex = 1
    this.getApplication();
    this.setB2CEmail();
    this.createApplicationFunction = app => this.applicationService.createApplication(app);
  }

  setLoggedOut(): void {
    this.selectedIndex = 0
    this.loading = false;
    this.title = TextStrings.homeTitle
    this.homeOverview = TextStrings.homeOverview
  }

  setB2CEmail(): void {
    pipe(
      this.authService.getB2CEmail(),
      O.map(value => () => { this.b2cEmail = value }),
      O.fold(
        () => {
          console.error("Failed to find an emails claim")
          this.failedLoad = true
        },
        effect => effect()
      )
    )
  }

  getRecentComment(comments: Comment[]): Comment {
    comments.sort((a, b) => {
      if (a.time > b.time) {
        return -1
      } else if (a.time < b.time) {
        return 1
      } return 0
    })
    return comments[0]
  };

  getLatestReviewMessage(a: Option<TTMApplicationResponse>): Comment {
    return pipe(
      a,
      O.chain(a => a.applicationDetails.status == ApplicationStatus.Returned ? O.some(a) : O.none),
      O.chain(a => O.fromNullable(a.additionalDetails.reviewerMessages)),
      O.map(messages => this.getRecentComment(messages)),
      O.getOrElse(() => { return { comment: "", user: "", time: "" } })
    )
  }

  getApplication(): void {
    this.applicationService.getApplication()
      .subscribe(a => {
        this.application = a;
        this.loading = false;
        this.reviewMessage = this.getLatestReviewMessage(a);
      },
        error => {
          console.error(error);
          this.loading = false;
          this.failedLoad = true;
          if (error.status === 403) {
            this.forbiddenLoad = true;
          }
        }
      )
  }

  onSave(app: Option<TTMApplicationResponse>): void {
    this.application = app;
  }

  onSubmit(app: TTMApplicationResponse, tabs: MatTabGroup): void {
    this.inCreation = false;
    this.application = some(app);
    if (tabs != null) {
      tabs.selectedIndex = 1;
    }
  }

  enterAppCreation(tabs: MatTabGroup): void {
    this.inCreation = true;
    if (tabs != null) {
      tabs.selectedIndex = 2;
    }
  }

  login(): void {
    this.navbarService.setAction(Action.Login);
  }


}