import { ComponentPortal } from '@angular/cdk/portal';
import { Component, OnInit } from '@angular/core';
import { TextStrings } from '../../modular/text-strings';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  pageNotFound: ComponentPortal<ComponentPageNotFoundPortal>;

  constructor() { }

  ngOnInit(): void {

    this.pageNotFound = new ComponentPortal(ComponentPageNotFoundPortal);

  }

}


@Component({
  selector: 'component-page-not-found-portal',
  template: TextStrings.pageNotFoundText
})
export class ComponentPageNotFoundPortal { }