import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MsalBroadcastService, MsalInterceptor, MsalInterceptorConfiguration, MsalService, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { ENVIRONMENT, SharedModule } from 'shared';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { LoginStatusService } from './loginstatus.service'
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SupportContentComponent } from './support/support-content.component';
import { FaqComponent } from './faq/faq.component';
import { IntroComponent } from './intro/intro.component';
import { NavbarActionsService } from './navbaractions.service'
import { TabSelectionService } from './tabselection.service'
import {WarningComponent} from './warning/warning.component';

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      redirectUri: `${environment.redirectBase}`,
      authority: environment.loginAuthority,
      postLogoutRedirectUri: `${environment.redirectBase}`,
      knownAuthorities: [`${environment.authorityBase}`]
    },
    cache: {
      storeAuthStateInCookie: isIE // set to true for IE 11
    }
  });
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.backendURL, environment.backendScopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SupportContentComponent,
    FaqComponent,
    IntroComponent,
    WarningComponent
  ],
  imports: [
    SharedModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalBroadcastService,
    LoginStatusService,
    NavbarActionsService,
    TabSelectionService,
    {
      provide: ENVIRONMENT,
      useValue: environment
    },
    MsalService,
    { provide: LOCALE_ID, useValue: "en-NZ" },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
