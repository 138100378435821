import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'lib-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.css']
})
export class PhoneNumberComponent {

  @Input() fc: UntypedFormControl;
  @Input() label: string = "Contact phone number"
  @Input() array: boolean = false;

  constructor() { }

}
