<mat-card>
  <div fxlayout fxLayoutAlign="none center">
    <p class="form-label" fxFlex>{{label}}</p>
    <mat-icon>place</mat-icon>
  </div>
  <form [formGroup]="formGroup" fxLayout="column">
    <mat-form-field>
      <mat-label>Address</mat-label>
      <input matInput formControlName="address" maxlength="100">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Suburb</mat-label>
      <input matInput formControlName="suburb" maxlength="100">
    </mat-form-field>
    <mat-form-field>
      <mat-label>City / Town</mat-label>
      <input matInput formControlName="city" maxlength="100">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Postcode</mat-label>
      <input matInput formControlName="postcode" maxlength="100">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Country</mat-label>
      <input matInput [formControl]= "countryControl" [matAutocomplete]="auto" maxlength="100">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
          {{country}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  </form>
</mat-card>
