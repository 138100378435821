import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'lib-email-address',
  templateUrl: './email-address.component.html',
  styleUrls: ['./email-address.component.css']
})
export class EmailAddressComponent {

  @Input() fc: UntypedFormControl;
  @Input() label: string = "Email address"
  @Input() array: boolean = false;

  constructor() { }
}
