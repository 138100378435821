<div class="container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px">
  <mat-form-field fxFlex>
    <mat-label>{{ label }}</mat-label>
    <ngx-mat-file-input [formControl]="file" [accept]="['.pdf','.jpg', '.jpeg', '.gif', '.tif', 'tiff','.png']">
    </ngx-mat-file-input>
    <mat-error *ngIf="file.hasError('fileExtensionValid')">
      Please select valid file type ('pdf', '.jpg', '.png', '.gif' or '.tif')
    </mat-error>
    <mat-icon matSuffix inline="true">folder</mat-icon>
    <mat-hint *ifSome="maybeHint; let hint">
      <div *ifSome="maybeHintLink; else noLink; let link">
        <a href="{{ link }}" rel="noopener noreferrer" target="_blank">{{ hint }} <mat-icon>launch</mat-icon></a>
      </div>
      <ng-template #noLink>{{ hint }}</ng-template>
    </mat-hint>
    <mat-error *ngIf="file.hasError('maxContentSize')">
      The file is too large - size must not exceed {{file.getError('maxContentSize').maxSize | byteFormat}} (actual size
      is {{file.getError('maxContentSize').actualSize
      | byteFormat}}).
    </mat-error>
  </mat-form-field>
  <ng-container *ifSome="maybeExistingUpload; else noExistingUpload; let u">
    <lib-button-spinner *ngIf="editable" [spinning]="deleting" buttonType="mat-mini-fab" color="secondary"
      (buttonClick)="delete()" labelText="Delete">
      <mat-icon>delete</mat-icon>
    </lib-button-spinner>
  </ng-container>
  <ng-template #noExistingUpload>
    <lib-button-spinner *ngIf="editable" [spinning]="uploading" buttonType="mat-mini-fab" color="primary"
      [disabled]="file.pristine || file.invalid" (buttonClick)="upload()" labelText="Upload">
      <mat-icon>arrow_upward</mat-icon>
    </lib-button-spinner>
  </ng-template>
</div>