import { AfterViewChecked, Component, ElementRef, OnInit } from '@angular/core';
import { FAQ, FAQDict, FaqService } from './faq.service';
import { ViewEncapsulation } from '@angular/core';
import { Action, NavbarActionsService } from '../navbaractions.service';
import { LoginStatusService } from '../loginstatus.service';
import { TabSelectionService } from '../tabselection.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FaqComponent implements OnInit, AfterViewChecked {

  faqsdict: FAQDict[];

  constructor(private faqService: FaqService, private navbarService: NavbarActionsService, private loginService: LoginStatusService, private tabService: TabSelectionService, private elementRef: ElementRef) { }

  _loggedIn: boolean;
  get loggedIn(): boolean {
    return this._loggedIn;
  }
  set loggedIn(value: boolean) {
    this._loggedIn = value;
    this.removeListeners()
    this.addListeners()
  }

  ngOnInit(): void {
    //TODO: Do actual error handling if we use a real service
    this.faqService.getFaqsDict().subscribe(res => {
      this.faqsdict = res;
      this.removeListeners()
      this.addListeners()
    })

    this.loginService.isLoggedIn.subscribe(b => {
      this.loggedIn = b
    })
  }

  ngAfterViewChecked() {
    this.removeListeners()
    this.addListeners()
  }

  addListeners() {
    if (this.loggedIn != null) {
      (<HTMLElement>this.elementRef.nativeElement).querySelectorAll('a.open-support').forEach(el => {
        el.addEventListener('click', this.raiseSupport.bind(this))
        el.setAttribute("href", "javascript:void(0)")
      })
    }
  }

  removeListeners() {
    (<HTMLElement>this.elementRef.nativeElement).querySelectorAll('a.open-support').forEach(el => {
      el.removeAllListeners("click")
    })
  }

  raiseSupport() {
    if (this.loggedIn) {
      this.navbarService.setAction(Action.ContactUs)
    } else {
      this.navbarService.setAction(Action.Login)
    }
  }

}
