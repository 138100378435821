import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'shared';
import { MatDialog } from '@angular/material/dialog';
import { SupportContentComponent } from "projects/ttm-portal/src/app/support/support-content.component";
import { Router } from '@angular/router';
import { LoginStatusService } from './loginstatus.service';
import { Action, NavbarActionsService } from './navbaractions.service';
import { WarningComponent } from './warning/warning.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Client Remediation Portal';
  loggedIn = false;

  isIE =
    window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    window.navigator.userAgent.indexOf("Trident/") > -1;

  constructor(private authService: AuthenticationService, public dialog: MatDialog, private loginService: LoginStatusService, private navbarService: NavbarActionsService) { }

  ngOnInit(): void {
    this.authService.handleRedirect().subscribe({
      next: () => {
        this.setLoggedInStatus();
        // if(this.loggedIn === false){
        //   this.openWarningDialog();
        // }
      },
      error: (err) => this.authService.processAuthError(err)
    });
    this.navbarService.getActionSubject.subscribe(a => {
      if (a == Action.Login) {
        this.login()
      } else if (a == Action.Logout) {
        this.logout()
      } else if (a == Action.ContactUs) {
        this.openSupportDialog()
      }
    })
  }

  setLoggedInStatus() {
    this.loggedIn = this.authService.isLoggedIn();
    this.loginService.setLoggedIn(this.loggedIn)
  }

  logout(): void {
    this.authService.handleRedirect().subscribe({
      next: () => {
        if (this.loggedIn) {
          this.authService.logout();
        }
        this.setLoggedInStatus();
      },
      error: (err) => this.authService.processAuthError(err)
    });
  }

  login(): void {
    this.authService.handleRedirect().subscribe({
      next: () => {
        if (!this.loggedIn) {
          this.authService.login();
        }
        this.setLoggedInStatus();
      },
      error: (err) => this.authService.processAuthError(err)
    });
  }

  openSupportDialog() {
    let dialogRef = this.dialog.open(SupportContentComponent,
      {
        position: { top: '10vh' }, minWidth: '45vw', maxHeight: '85vh'
      }
    )

    dialogRef.componentInstance.onSupportSubmit
      .subscribe(a => {
        dialogRef.close()
      })
  }

  openWarningDialog() {
    let dialogRef = this.dialog.open(WarningComponent,
      {
        position: { top: '10vh' }, minWidth: '45vw', maxHeight: '85vh', maxWidth: '800px'
      }
    )
  }

}
