
<div class="mat-typography">
  <div class="navbar-content-container">
  <div fxLayout="column" fxLayoutAlign="center center" id="nav">
  <div class="disclaimer-container" fxFlex fxLayout="row" fxLayoutAlign="center center">
    <div class="disclaimer" fxFlex fxLayout="row" fxLayoutAlign="end center">
      <span class="disclaimer-title">NBS is not a registered bank</span>
    </div>
  </div>
  <mat-toolbar color="primary" class="navbar maybe-elevate-toolbar">
    <a id="nav-brand"><img alt="NBS" src="assets/images/nbs-logo.svg" id="nav-logo"></a>
    <span class="nav-title">Client Remediation Portal</span>
    

    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end" fxHide.lt-sm>
      <button *ngIf="loggedIn" mat-button (click)="openSupportDialog()" class="nav-button contact">
        <mat-icon class="nav-icon" inline="true">people</mat-icon> Contact Us
      </button>
      <div></div>
      <button *ngIf="loggedIn" mat-button (click)="logout()" class="nav-button">
        <mat-icon class="nav-icon" inline="true">logout</mat-icon> Logout
      </button>
      <button *ngIf="!loggedIn" mat-button (click)="login()" class="nav-button">
        <mat-icon class="nav-icon" inline="true">login</mat-icon> Login / Register
      </button>
    </div>

    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end" fxHide.gt-xs>

      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon class="nav-menu-icon">menu</mat-icon>
      </button>

      <mat-menu #menu>
        <button *ngIf="loggedIn" mat-menu-item (click)="openSupportDialog()">
          <mat-icon class="menu-icon" inline="true">people</mat-icon>Contact Us
        </button>

        <button *ngIf="loggedIn" mat-menu-item (click)="logout()">
          <mat-icon class="menu-icon" inline="true">logout</mat-icon>Logout
        </button>

        <button *ngIf="!loggedIn" mat-menu-item (click)="login()">
          <mat-icon class="menu-icon" inline="true">login</mat-icon>Login / Register
        </button>
      </mat-menu>

    </div>

  </mat-toolbar>
  </div>
</div>
  <mat-toolbar *ngIf="isIE" class="browser-warning" color="warn" fxLayout="row" fxLayoutAlign="center center"><span>It
      looks like
      you're using Internet Explorer. We recommend using a modern browser such as Chrome as we cannot guarantee all the
      features of this portal will work.</span></mat-toolbar>

  <main>
    <router-outlet></router-outlet>
  </main>
</div>