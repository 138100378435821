import { Component } from '@angular/core';

@Component({
  selector: 'warning.component',
  templateUrl: 'warning.component.html',
  styleUrls: ['./warning.component.css']
})

export class WarningComponent {


constructor() { }

}