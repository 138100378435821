<form [formGroup]="formGroup">
  <p *ngIf="!editable" class="form-step-header">We need some documents to support your application (pdf or images
    accepted)</p>
  <div class="container" fxLayout="column" fxLayoutAlign="center none" fxLayoutGap="25px">
    <mat-accordion class="example-headers-align">

      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Proof of Identity
            <mat-icon color="warn" *ngIf="proofOfIdentity.invalid">error</mat-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [formArrayName]="'proofOfIdentity'">
          <div *ngFor="let control of proofOfIdentity.controls; let i = index;">
            <lib-upload-file [existingUpload]="control.value" [editable]="editable" documentName="proofOfIdentity"
              label="Select File" [maybeHint]="some(hints['proofOfIdentity'])" (uploaded)="onUpload($event, proofOfIdentity, i)" (deleted)="onDelete($event, proofOfIdentity, i)" [appId]="appId">
            </lib-upload-file>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Proof of Bank Account
            <mat-icon color="warn" *ngIf="proofOfBankAccount.invalid">error</mat-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [formArrayName]="'proofOfBankAccount'">
          <div *ngFor="let control of proofOfBankAccount.controls; let i = index;">
            <lib-upload-file [existingUpload]="control.value" documentName="proofOfBankAccount" [editable]="editable"
              label="Select File" (uploaded)="onUpload($event, proofOfBankAccount, i)"
              [maybeHint]="some(hints['proofOfBankAccount'])"
              (deleted)="onDelete($event, proofOfBankAccount, i)" [appId]="appId">
            </lib-upload-file>
          </div>
          <hr>
          <p>Please ensure all uploaded proof of NZ bank account documents show the bank’s logo and full account number.</p>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</form>