import { Injectable } from '@angular/core';
import { values } from 'fp-ts/lib/ReadonlyMap';
import { Observable, of } from 'rxjs';
import { TextStrings } from 'shared';
import { MatTreeModule } from '@angular/material/tree';
@Injectable({
  providedIn: 'root'
})
export class FaqService {

  faqsdict: FAQDict[] = TextStrings.faqs

  constructor() { }

  getFaqsDict(): Observable<FAQDict[]> {
    return of(this.faqsdict);
  }
}

export interface FAQ {
  question: string;
  answer: string;
}

export interface FAQDict {
  key: string,
  value: FAQ[]
}
