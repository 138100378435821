import { InjectionToken } from '@angular/core';
export interface ApplicationRequest {
  status: ApplicationStatus;
  formDetails: ApplicationFormDetails;
}

export interface BaseAdditionalDetails {
  reviewerMessages: Comment[];
  supportingDocuments: SupportingDocuments;
  adminControlled: boolean;
}

export interface BaseApplicationResponse<T extends BaseAdditionalDetails> {
  id: string;
  applicationDetails: ApplicationRequest;
  additionalDetails: T
}

export interface TTMAdditionalItemDetails extends BaseAdditionalDetails { }

export interface AdminAdditionalItemDetails extends BaseAdditionalDetails {
  criticalErrors: FieldValidationError[],
  minorErrors: FieldValidationError[];
  comments: Comment[];
  b2cEmail: string;
  supportRequests: SupportRequest[];
  paidRemediationDetails: PaidRemediationDetail[];
  calculatedRemediationDetails: CalculatedRemediationDetail[];
}

export interface PaymentPendingEmployeeError {
  errors: string[];
  results: PaymentPendingEmployeeResult[];
  reason: PaymentPendingEmployeeFileErrorReason;
}

export enum PaymentPendingEmployeeFileErrorReason {
  InvalidData = 1,
  InvalidFile = 2,
  ApplicationError = 3
}

export interface PaymentPendingEmployeeResult {
  clientId: string,
  historicalAllowanceID: string,
  outcome: PaymentPendingEmployeeOutcome
}

export enum PaymentPendingEmployeeOutcome {
  Success = 0,
  MissingClientId = 1,
  IncorrectStatus = 2,
  PaymentAlreadyPresent = 3
}

export interface CalculatedRemediationDetail {
  assignedTime: string;
  latestBatchID: string;
  totalLeavePayable: string;
  totalTerminationPayable: string;
  latestRecalculatedDate: string;
  totalRecalculatedPayable: string;
}

export interface PaidRemediationDetail {
  assignedTime: string;
  terminationDate: string;
  allowanceCode: string;
  description: string;
  totalAmount: string;
  historicalAllowanceID: string;
}

export interface SupportRequest {
  time: string;
  message: string;
  email: string;
  name: string;
  phone: string;
}

export interface TTMApplicationResponse extends BaseApplicationResponse<TTMAdditionalItemDetails> { }

export interface AdminApplicationResponse extends BaseApplicationResponse<AdminAdditionalItemDetails> { }

export interface ApplicationFormDetails {
  personalDetails: PersonalDetails;
  contactDetails: ContactDetails;
  bankAndTaxDetails: BankAndTaxDetails;
  historicDetails: HistoricDetails;
}

export interface PersonalDetails {
  isDeceasedEstate: boolean;
  givenNames: string;
  surname: string;
  previousNames: Array<string>;
  dateOfBirth: string;
}

export interface ContactDetails {
  phoneNumber: string;
  contactAddress: Address;
}

export interface Address {
  address: string;
  suburb: string;
  city: string;
  postcode: string;
  country: string;
}

export interface BankAndTaxDetails {
  bankAccountNumber: string;
  clientId: string;
  isOverseasAccount: boolean;
  overseasAccountDetails: string;
}

export interface HistoricDetails {
  previousHomePhoneNumbers: string[];
  previousMobilePhoneNumbers: string[];
  previousEmailAddresses: string[];
  previousBankAccounts: string[];
  previousAddresses: Address[];
}

export interface SupportingDocuments {
  proofOfIdentity: FileUpload[];
  proofOfBankAccount: FileUpload[];
}

export interface Comment {
  comment: string;
  user: string;
  time: string;
}

export enum ApplicationStatus {
  Incomplete = 0,
  Submitted = 1,
  AwaitingReview = 2,
  Returned = 3,
  IdConfirmed = 4,
  RemPending = 5,
  RemPaymentPending = 6,
  RemPaid = 7,
  Rejected = 8,
  NoRemediationCalculated = 9
}


export interface SupportDetails {
  name: string;
  phone: string;
  message: string;
}

export interface FieldValidationError {
  fieldName: string;
  message: string;
  applicationValues: string[];
  previousEmployeeValues: string[];
}

export interface FileUpload {
  fileName: string;
  filePath: string;
  id: string;
}

export interface Comment {
  comment: string;
  user: string;
  time: string;
}

export interface DownloadDetails {
  startDate: Date;
  endDate: Date;
}

export interface PaidEmployeeError {
  errors: string[];
  results: PaidEmployeeResult[];
  reason: PaidEmployeeFileErrorReason;
}

export enum PaidEmployeeFileErrorReason {
  InvalidData = 1,
  InvalidFile = 2,
  ApplicationError = 3
}

export interface PaidEmployeeResult {
  clientId: string,
  historicalAllowanceID: string,
  outcome: PaidEmployeeOutcome
}

export enum PaidEmployeeOutcome {
  Success = 0,
  MissingClientID = 1,
  IncorrectStatus = 2,
  PaymentAlreadyPresent = 3
}

export const ENVIRONMENT = new InjectionToken('ENVIRONMENT');
