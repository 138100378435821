<div class="container">
  <div fxLayout="column" fxLayoutAlign="center center">
    <mat-vertical-stepper [linear]="!viewerMode" #stepper class="multi-step-form create-application-form"
      (selectionChange)="selectionChanged()">
      <mat-step [editable]="!submitting" [stepControl]="personalDetailsForm">
        <ng-template matStepLabel>Personal details</ng-template>
        <lib-personal-details [formGroup]="personalDetailsForm" [readOnly]="readOnly" [nameControl]="nameControl" [adminMode]="adminMode">
        </lib-personal-details>
        <lib-form-step-controls *ngIf="!viewerMode" (saveRequested)="save()" [saving]="saving"
          [parentForm]="applicationForm" [formGroup]="personalDetailsForm"></lib-form-step-controls>
      </mat-step>
      <mat-step [editable]="!submitting" [stepControl]="contactDetailsForm">
        <ng-template matStepLabel>Current contact details</ng-template>
        <lib-contact-details [formGroup]="contactDetailsForm" [contactEmail]="contactEmail"></lib-contact-details>
        <lib-form-step-controls *ngIf="!viewerMode" (saveRequested)="save()" [saving]="saving"
          [parentForm]="applicationForm" [formGroup]="contactDetailsForm"></lib-form-step-controls>
      </mat-step>
      <mat-step [editable]="!submitting" [stepControl]="bankAndTaxDetailsForm">
        <ng-template matStepLabel>Bank account details</ng-template>
        <lib-bank-tax-details [formGroup]="bankAndTaxDetailsForm" [readOnly]="readOnly" [adminMode]="adminMode"></lib-bank-tax-details>
        <lib-form-step-controls *ngIf="!viewerMode" (saveRequested)="save()" [saving]="saving"
          [parentForm]="applicationForm" [formGroup]="bankAndTaxDetailsForm"></lib-form-step-controls>
      </mat-step>
      <mat-step *ngIf="!viewerMode" [editable]="!submitting" [stepControl]="supportingDocumentsForm">
        <ng-template matStepLabel>Supporting documents</ng-template>
        <lib-supporting-documents [editable]="!readOnly" [formGroup]="supportingDocumentsForm" [appId]="appId">
        </lib-supporting-documents>
        <lib-form-step-controls *ngIf="!viewerMode && !adminMode" (saveRequested)="save()" [saving]="saving"
          [parentForm]="applicationForm" [formGroup]="supportingDocumentsForm" [editable]="!readOnly">
        </lib-form-step-controls>
      </mat-step>
      <mat-step *ngIf="!viewerMode && !adminMode">
        <ng-template matStepLabel>Submit</ng-template>
        <p>Please note that you will not be able to update your application details after submission, so take the time
          now to verify the information you have provided and make any necessary changes.</p>

        <p>
          <ng-template [cdkPortalOutlet]="privacyDisclaimer"></ng-template>
        </p>

        <p>
          <mat-checkbox #infoCheck>I confirm that the provided information is correct</mat-checkbox>
        </p>

        <p>
          <mat-checkbox #accountCheck>I confirm that I am the owner of the stated bank account entered
            under Bank and Tax details</mat-checkbox>
        </p>
        <div fxLayout="row" fxLayoutAlign="center center">
          <lib-button-spinner [spinning]="submitting" buttonType="mat-raised-button" color="primary"
            [disabled]="applicationForm.invalid || !accountCheck.checked || !infoCheck.checked"
            (buttonClick)="submit()">Submit Application
          </lib-button-spinner>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</div>