import { Component, Input, OnInit } from '@angular/core';
import { ApplicationStatus } from '../models';



@Component({
  selector: 'lib-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.css']
})
export class StatusIndicatorComponent implements OnInit {

  @Input() statusName: [string, string];

  constructor() { }

  ngOnInit(): void {
  }

}
