import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from "@angular/material/dialog";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PortalModule } from '@angular/cdk/portal';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxFpTsModule } from '../ngx-fp-ts/ngx-fp-ts.module';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialFileInputModule } from '../material-file-input/material-file-input.module';
import { ButtonSpinnerComponent } from './button-spinner/button-spinner.component';
import { BankAndTaxDetailsComponent } from './create-application/bank-tax-details/bank-tax-details.component';
import { ContactDetailsComponent } from './create-application/contact-details/contact-details.component';
import { ComponentPrivacyDisclaimerPortal, CreateApplicationComponent } from './create-application/create-application.component';
import { AddressComponent } from './create-application/form-components/address/address.component';
import { ArrayInputComponent } from './create-application/form-components/array-input/array-input.component';
import { BankAccountComponent } from './create-application/form-components/bank-account/bank-account.component';
import { EmailAddressComponent } from './create-application/form-components/email-address/email-address.component';
import { PhoneNumberComponent } from './create-application/form-components/phone-number/phone-number.component';
import { FormStepControlsComponent } from './create-application/form-step-controls/form-step-controls.component';
import { PersonalDetailsComponent } from './create-application/personal-details/personal-details.component';
import { SupportingDocumentsComponent } from './create-application/supporting-documents/supporting-documents.component';
import { UploadFileComponent } from './create-application/upload-file/upload-file.component';
import { ComponentPageNotFoundPortal, PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { StatusIndicatorComponent } from './status-indicator/status-indicator.component';
import { MatTabsModule } from '@angular/material/tabs';
import { DatePipe } from '@angular/common'


const DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@NgModule({
  declarations: [
    CreateApplicationComponent,
    UploadFileComponent,
    PersonalDetailsComponent,
    ArrayInputComponent,
    FormStepControlsComponent,
    BankAndTaxDetailsComponent,
    SupportingDocumentsComponent,
    AddressComponent,
    ComponentPrivacyDisclaimerPortal,
    ComponentPageNotFoundPortal,
    ContactDetailsComponent,
    PhoneNumberComponent,
    BankAccountComponent,
    EmailAddressComponent,
    ButtonSpinnerComponent,
    PageNotFoundComponent,
    StatusIndicatorComponent
  ],
  imports: [
    MatExpansionModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxFpTsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatBadgeModule,
    MatIconModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskModule.forRoot(),
    MaterialFileInputModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatButtonToggleModule,
    MomentDateModule,
    MatDatepickerModule,
    MatCheckboxModule,
    PortalModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
    MatDialogModule,
    MatTabsModule
  ],
  exports: [
    MatExpansionModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxFpTsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatBadgeModule,
    MatIconModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskModule,
    MaterialFileInputModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatButtonToggleModule,
    MomentDateModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatMenuModule,
    MatAutocompleteModule,
    CreateApplicationComponent,
    UploadFileComponent,
    PortalModule,
    PersonalDetailsComponent,
    ArrayInputComponent,
    FormStepControlsComponent,
    BankAndTaxDetailsComponent,
    SupportingDocumentsComponent,
    AddressComponent,
    ContactDetailsComponent,
    PhoneNumberComponent,
    BankAccountComponent,
    EmailAddressComponent,
    ButtonSpinnerComponent,
    PageNotFoundComponent,
    MatChipsModule,
    StatusIndicatorComponent,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
    MatDialogModule,
    MatTabsModule
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-NZ'
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMAT
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 5000, panelClass: ["snackbar-theme"] }
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { strict: true, useUtc: true }
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    },
    DatePipe
  ]
})
export class SharedModule { }
