import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { countries } from './countries'


@Component({
  selector: 'lib-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup;
  @Input() label: string = "Contact Address"

  countryControl: UntypedFormControl

  filteredCountries: Observable<string[]>;
  countries = countries

  constructor() { }

  ngOnInit() {
    this.countryControl = this.formGroup.get("country") as UntypedFormControl
    this.filteredCountries = this.countryControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(country => country.toLowerCase().indexOf(filterValue) === 0);
  }

}
