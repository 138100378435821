import { Component, Input, TemplateRef } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'lib-array-input',
  templateUrl: './array-input.component.html',
  styleUrls: ['./array-input.component.css']
})
export class ArrayInputComponent {

  @Input() label: string;
  @Input() formArrayName: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() itemTemplate: TemplateRef<any>;
  @Input() emptyArrayElement: () => AbstractControl = () => new UntypedFormControl('', Validators.required);
  @Input() maxItems: number = 3;
  @Input() readOnly: boolean;

  formArray: UntypedFormArray;

  ngOnInit(): void {
    this.formArray = this.formGroup.get(this.formArrayName) as UntypedFormArray;
  }

  maxArraySizeReached(): boolean {
    return this.formArray.length === this.maxItems;
  }

  addItem() {
    //Want error to show if user attempts to add a new item when the current one is invalid
    if(this.formArray.length > 0) {
      this.formArray.markAllAsTouched();
    }

    if(!this.maxArraySizeReached() && this.formArray.valid) {
      this.formArray.push(this.emptyArrayElement());
    }
  }

  removeItem(i: number) {
    this.formArray.removeAt(i);
    this.formArray.markAsDirty();
  }

  //This is needed for the focus to remain when typing in a formarray field. Not sure why but it works.
  //See https://stackoverflow.com/questions/42322968/angular2-dynamic-input-field-lose-focus-when-input-changes
  trackByFn(index: any, item: any) {
    return index;
  }

}
