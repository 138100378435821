import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, AbstractControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fromNullable, getOrElse, map, none, Option, some } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/pipeable';
import { FileInputComponent } from '../../../material-file-input/file-input/file-input.component';
import { FileInput } from '../../../material-file-input/model/file-input.model';
import { FileValidator } from '../../../material-file-input/validator/file-validator';
import { UploadService } from './upload.service';
import { FileUpload } from '../../models';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'lib-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

  file: UntypedFormControl;
  readonly maxSize = 20971520;
  @Input() existingUpload: FileUpload;
  @Input() documentName: string = "";
  @Input() label: string = "";
  @Input() maybeHint: Option<string> = none;
  @Input() maybeHintLink: Option<string> = none;
  @Input() editable: boolean = true;
  @Input() appId: Option<string> = none;
  @Output() uploaded: EventEmitter<[string, FileUpload]> = new EventEmitter<[string, FileUpload]>();
  @Output() deleted: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(FileInputComponent) fileInput: FileInputComponent;
  maybeExistingUpload: Option<FileUpload>;
  uploading: boolean = false;
  deleting: boolean = false;
  uploadFile: (documentName: string, formData: FormData) => Observable<FileUpload>;
  deleteFile: (documentName: string, documentId: string) => Observable<any>;

  constructor(private uploadService: UploadService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.maybeExistingUpload = fromNullable(this.existingUpload);
    this.file = new UntypedFormControl('file');

    pipe(
      this.maybeExistingUpload,
      map(u => {
        this.file.setValue(new FileInput([new CustomFile(u.fileName)]));
        this.file.disable();
      }),
      getOrElse(() => {
        this.file.setValue(new FileInput([]));
      })
    );

    this.file.setValidators([fileValidator, FileValidator.maxContentSize(this.maxSize)])

    if (!this.editable) {
      this.file.disable();
    }

    this.uploadFile = pipe(
      this.appId,
      map(id => (docName, formData) => this.uploadService.uploadById(id, docName, formData)),
      getOrElse(() => (docName, formData) => this.uploadService.upload(docName, formData))
    )

    this.deleteFile = pipe(
      this.appId,
      map(id => (docName, docId) => this.uploadService.deleteById(id, docName, docId)),
      getOrElse(() => (docName, docId) => this.uploadService.delete(docName, docId))
    )
  }

  upload(): void {
    if (this.file.valid) {
      if (sessionStorage.getItem('UPLOADING_FILE') === 'true') {
        this.snackBar.open('Please await existing file upload.', 'Dismiss');
        return;
      }
      sessionStorage.setItem('UPLOADING_FILE', 'true');
      const formData = new FormData();
      const fileData = this.file.value.files[0];
      formData.append('file', fileData, fileData.name);
      this.uploading = true;
      this.uploadFile(this.documentName, formData).subscribe(
        (r) => {
          sessionStorage.removeItem('UPLOADING_FILE');
          this.existingUpload = r;
          this.maybeExistingUpload = some(r);
          this.snackBar.open('File uploaded successfully.', 'Dismiss');
          this.file.disable();
          this.uploaded.emit([this.documentName, r]);
          this.uploading = false;
        },
        (error: HttpErrorResponse) => {
          sessionStorage.removeItem('UPLOADING_FILE');
          console.error(error);
          this.uploading = false;
          if (error.status == 403) {
            this.snackBar.open(
              'The file you uploaded was identified as being malicious. Please choose a different file and try again later.',
              'Dismiss'
            );
          } else if (error.status == 429) {
            this.snackBar.open(
              'A file you previously uploaded was identified as being malicious. Please try again later.',
              'Dismiss'
            );
          } else {
            this.snackBar.open(
              'Failed to upload file. Please try again later.',
              'Dismiss'
            );
          }
        }
      );
    }
  }

  delete(): void {
    this.deleting = true;
    this.deleteFile(this.documentName, this.existingUpload.id)
      .subscribe(r => {
        this.maybeExistingUpload = none;
        this.deleted.emit(this.documentName);
        this.fileInput.clear();
        this.file.reset();
        this.file.enable();
        this.deleting = false;
      }, err => {
        console.error(err);
        this.snackBar.open("Failed to delete file. Please try again later.", "Dismiss")
        this.deleting = false;
      })
  }
}

export function fileValidator(control: AbstractControl) {

  const file = control.value;

  if (file?.files.length == 0) {
    return null;
  }

  try {
    if (!file.fileNames.toLowerCase().endsWith(".png")
      && !file.fileNames.toLowerCase().endsWith(".jpg")
      && !file.fileNames.toLowerCase().endsWith(".jpeg")
      && !file.fileNames.toLowerCase().endsWith(".gif")
      && !file.fileNames.toLowerCase().endsWith(".tif")
      && !file.fileNames.toLowerCase().endsWith(".tiff")
      && !file.fileNames.toLowerCase().endsWith(".pdf")) {
      return { fileExtensionValid: true }
    }
  } catch (e) {
    return { fileExtensionValid: true }
  }
  return null;
}

//new File() is not supported in IE
//As the only thing we actually need is the filename (it's just a UI thing), this CustomFile just has all other file properties set to null
export class CustomFile {
  constructor(filename) {
    this.name = filename;
  }
  name; lastModified; size; type; arrayBuffer; slice; stream; text; webkitRelativePath;
}
