import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TextStrings } from '../../../modular/text-strings';


@Component({
  selector: 'lib-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.css']

})
export class PersonalDetailsComponent implements OnInit, OnDestroy {


  isExpanded: boolean = false;

  @Input() formGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  @Input() adminMode: boolean;
  @Input() nameControl: () => AbstractControl;
  maxDOB: Date;
  minDOB: Date;
  isDeceasedEstateSubscription: Subscription;
  deceasedEstateInfo: string;

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    this.maxDOB = new Date(currentYear - 13, 0, 1);
    this.minDOB = new Date("1910-01-01");
    let isDeceasedEstate = this.formGroup.get("isDeceasedEstate")
    this.isExpanded = isDeceasedEstate.value ?? false
    this.isDeceasedEstateSubscription = isDeceasedEstate.valueChanges.subscribe(v => {
      if (v) {
        this.isExpanded = true
      }
    })
    this.deceasedEstateInfo = TextStrings.deceasedEstateInfo;
  }

  ngOnDestroy(): void {
    this.isDeceasedEstateSubscription.unsubscribe();
  }

}

