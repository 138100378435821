import { AdminApplicationResponse, ApplicationStatus } from './models'

export const testEnv = {
  backendURL: "http://localhost:4200"
}


export function emptyApplication(): AdminApplicationResponse {
  return {
    id: "1",
    applicationDetails: {
      status: ApplicationStatus.Incomplete,
      formDetails: {
        personalDetails: {
          isDeceasedEstate: null,
          givenNames: null,
          surname: null,
          previousNames: [],
          dateOfBirth: null
        },
        contactDetails: {
          phoneNumber: null,
          contactAddress: {
            address: null,
            suburb: null,
            city: null,
            postcode: null,
            country: "New Zealand"
          }
        },
        bankAndTaxDetails: {
          bankAccountNumber: null,
          clientId: null,
          isOverseasAccount: null,
          overseasAccountDetails: null
        },
        historicDetails: {
          previousHomePhoneNumbers: [],
          previousMobilePhoneNumbers: [],
          previousEmailAddresses: [],
          previousBankAccounts: [],
          previousAddresses: []
        }
      }
    },
    additionalDetails: {
      adminControlled: false,
      comments: null,
      reviewerMessages: null,
      criticalErrors: null,
      minorErrors: null,
      supportRequests: null,
      paidRemediationDetails: null,
      calculatedRemediationDetails: null,
      b2cEmail: "john@doe.com",
      supportingDocuments: {
        proofOfIdentity: null,
        proofOfBankAccount: null,
      }
    }
  }
}

export function completeApplication(): AdminApplicationResponse {
  return {
    id: "1",
    applicationDetails: {
      status: ApplicationStatus.Submitted,
      formDetails: {
        personalDetails: {
          isDeceasedEstate: false,
          givenNames: "John",
          surname: "Doe",
          previousNames: [],
          dateOfBirth: "1984-06-12T00:00:00.000Z"
        },
        contactDetails: {
          phoneNumber: "020123456",
          contactAddress: {
            address: "1 Street Name",
            suburb: "Great Suburb",
            city: "Wellington",
            postcode: "6034",
            country: "New Zealand"
          }
        },
        bankAndTaxDetails: {
          bankAccountNumber: "02-0552-0009505-30",
          clientId: "491850",
          isOverseasAccount: false,
          overseasAccountDetails: null
        },
        historicDetails: {
          previousHomePhoneNumbers: [],
          previousMobilePhoneNumbers: [],
          previousEmailAddresses: [],
          previousBankAccounts: [],
          previousAddresses: []
        }
      }
    },
    additionalDetails: {
      adminControlled: false,
      comments: [],
      reviewerMessages: null,
      criticalErrors: null,
      minorErrors: null,
      supportRequests: null,
      calculatedRemediationDetails: null,
      paidRemediationDetails: null,
      b2cEmail: "john@doe.com",
      supportingDocuments: {
        proofOfIdentity:
          [
            {
              fileName: "file1.pdf",
              filePath: "/path/to/file",
              id: "1"
            }
          ],
        proofOfBankAccount: [
          {
            fileName: "file3.pdf",
            filePath: "/path/to/file",
            id: "3"
          }
        ]
      }
    }
  }
}
