<form [formGroup]="formGroup">
  <div class="container" fxLayout="column" fxLayoutGap="20px">
    <!-- <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <div fxFlex>
        <div class="container" fxLayout="column" fxLayoutAlign="center none">
          <mat-checkbox formControlName="isOverseasAccount">I have a bank account with an overseas bank</mat-checkbox>
        </div>
      </div>
    </div> -->
    <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <div fxFlex>
        <div class="container" fxLayout="column" fxLayoutAlign="center none">
          <mat-accordion multi displayMode="flat">
            <mat-expansion-panel disabled expanded *ngIf="adminMode">
              <div class="overseas-box">
                <mat-checkbox formControlName="isOverseasAccount">I only have a bank account with a non-New Zealand bank
                </mat-checkbox>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="isExpanded" (opened)="isExpanded = true" (closed)="isExpanded = false"
              *ngIf="!readOnly && adminMode">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="container" fxLayout="row" fxLayoutGap="10px">
                    <div fxFlex="1 1 25px" fxLayout="column" fxLayoutAlign="center center">
                      <mat-icon>help</mat-icon>
                    </div>
                    <span>How do I request a remediation payment to an overseas bank account?</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>If payment is to be made to an overseas bank account please ensure you provide the following:</p>
              <ul>
                <li>The account number and currency of your overseas account</li>
                <li>The name, branch and address of the bank</li>
                <li>The International Bank Account Number (IBAN) or similar of your account</li>
                <li>The Swift, BIC or Routing Code</li>
              </ul>

              <p>
                Your bank will be able to assist to find the correct information, and all uploaded proof of account
                documents will need to show the bank’s logo and the full account number.
              </p>
              <p>If you are unsure of how to complete the application please ‘Contact Us’ form in the online portal.</p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
    <div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <div fxFlex>
        <div class="container" fxLayout="column" fxLayoutAlign="center none">
          <mat-form-field>
            <mat-label>Client Number</mat-label>
            <input matInput formControlName="clientId" mask="0{9}">
            <mat-error *ngIf="formGroup.get('clientId').hasError('validateClientId')">
              Please enter a valid Client Number, this must be 5 to 7 digits, without spaces or dashes and can be found on the communication sent to you via email or post.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxFlex>
        <div class="container" fxLayout="column" fxLayoutAlign="center none">
          <ng-container *ngIf="!formGroup.value.isOverseasAccount">
            <lib-bank-account [fc]="formGroup.get('bankAccountNumber')"></lib-bank-account>
          </ng-container>
          <ng-container *ngIf="formGroup.value.isOverseasAccount">
            <mat-form-field>
              <mat-label>Overseas Account Details:</mat-label>
              <textarea formControlName="overseasAccountDetails" class="overseas-message-box" matInput #message
                maxlength="256" required></textarea>
              <mat-hint align="start"><strong>Please enter as much information about your overseas account as
                  possible, including country and any relevant bank and account numbers</strong> </mat-hint>
              <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</form>