<div class="mat-typography">
  <h1 mat-dialog-title>How can we help?</h1>
  <p>If you require any support with lodging an application in the portal, please raise your query using this form and we will contact you on the email address provided during the sign-up process as soon as possible.</p>
  <p>For all other general queries, please call <a href="tel:+640800101700">0800-101-700</a> or email <a href="mailto:info@nbs.co.nz">info@nbs.co.nz</a></p>
  <form [formGroup]="supportForm">
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field>
        <mat-label>Name:</mat-label>
        <input matInput formControlName="name" required maxlength="80">
        <mat-icon matSuffix inline="true">person</mat-icon>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Message:</mat-label>
        <textarea class="support-message-box" matInput #message maxlength="256" formControlName="message"
          required></textarea>
        <mat-hint align="start"><strong>Please describe your issue above</strong> </mat-hint>
        <mat-hint align="end">{{message.value.length}} / 256</mat-hint>

      </mat-form-field>
    </div>
  </form>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
    <lib-button-spinner [spinning]="submitting" buttonType="mat-raised-button" color="primary"
      [disabled]="supportForm.invalid" (buttonClick)="submit()">
      Submit
    </lib-button-spinner>
    <button mat-button mat-dialog-close>Close</button>
  </div>
</div>