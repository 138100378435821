import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { none, Option, some, fromNullable } from 'fp-ts/es6/Option';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApplicationRequest, ENVIRONMENT, SupportDetails, TTMApplicationResponse } from 'shared';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  applicationURL: string;
  supportURL: string;

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: any) {
    this.applicationURL = this.environment.backendURL + '/application';
    this.supportURL = this.environment.backendURL + '/support'
  }

  getApplication(): Observable<Option<TTMApplicationResponse>> {
    return this.http.get<TTMApplicationResponse>(this.applicationURL, {observe: 'response'})
      .pipe(
        map(a => {
          if (a.status === 204){
            return none
          }
          else {
            return fromNullable(a.body)
          }
        }),
        catchError((error: Response) => {
          console.error(error);
          return throwError(error)
        })
      )
  }

  createApplication(application: ApplicationRequest): Observable<TTMApplicationResponse> {
    return this.http.put<TTMApplicationResponse>(this.applicationURL, application)
  }

  supportApplication(support: SupportDetails): Observable<SupportDetails> {
    return this.http.post<SupportDetails>(this.supportURL, support)
  }
}

