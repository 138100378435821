import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'lib-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.css']
})
export class ContactDetailsComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup;
  addressForm: UntypedFormGroup;
  @Input() contactEmail: string;
  constructor() { }

  ngOnInit(): void {
    this.addressForm = this.formGroup.get("contactAddress") as UntypedFormGroup;
  }

}
