import { ApplicationStatus } from "shared";

export const StatusName: { [key in ApplicationStatus]: [string, string] } = {
  [ApplicationStatus.Incomplete]: ["Incomplete", "secondary"],
  [ApplicationStatus.Submitted]: ["Submitted", "primary"],
  [ApplicationStatus.AwaitingReview]: ["Submitted", "primary"],
  [ApplicationStatus.Returned]: ["Returned", "warn"],
  [ApplicationStatus.IdConfirmed]: ["Identity Validated", "primary"],
  [ApplicationStatus.RemPending]: ["Identity Validated", "primary"],
  [ApplicationStatus.RemPaymentPending]: ["Payment Pending", "primary"],
  [ApplicationStatus.RemPaid]: ["Remediation Paid", "primary"],
  [ApplicationStatus.Rejected]: ["Rejected", "warn"],
  [ApplicationStatus.NoRemediationCalculated]: ["No Remediation Amount", "primary"],
}

export const StatusMessage: { [key in ApplicationStatus]: string } = {
  [ApplicationStatus.Incomplete]: "Your application is <b>incomplete</b>.",
  [ApplicationStatus.Submitted]: "Your application has been <b>submitted</b> and is currently being reviewed.",
  [ApplicationStatus.AwaitingReview]: "Your application has been <b>submitted</b> and is currently being reviewed.",
  [ApplicationStatus.Returned]: "We have assessed your application and cannot match your claim against our records of eligible clients. This may be due to errors in your application, or due to your details not matching an eligible client. Please review your application and confirm the details you have provided. If your details are incorrect, you can update your details and resubmit your claim.<br><br>If you have reviewed your details and believe your application has been returned in error, please contact us by submitting a support query through clicking ‘Contact Us’ in the remediation claims portal.",
  [ApplicationStatus.IdConfirmed]: "Your identity has been <b>validated</b> and we are in the process of calculating if you are eligible for a remediation payment.",
  [ApplicationStatus.RemPending]: "Your identity has been <b>validated</b> and we are in the process of calculating if you are eligible for a remediation payment.",
  [ApplicationStatus.RemPaymentPending]: "We have calculated your remediation amount and are processing the payment.",
  [ApplicationStatus.RemPaid]: "We have processed the payment of your remediation.",
  [ApplicationStatus.Rejected]: "We were unable to confirm your identity so your application has been rejected.",
  [ApplicationStatus.NoRemediationCalculated]: "We have reviewed your application and currently no remediation amount is currently owed. We will update your application if this changes in the future."
}
