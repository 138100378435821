import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'lib-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.css']
})
export class BankAccountComponent {

  @Input() fc: UntypedFormControl;
  @Input() label: string = "New Zealand bank account number"

  constructor() { }

}
