import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TextStrings } from '../../../modular/text-strings';

@Component({
  selector: 'lib-bank-tax-details',
  templateUrl: './bank-tax-details.component.html',
  styleUrls: ['./bank-tax-details.component.css']
})
export class BankAndTaxDetailsComponent implements OnInit, OnDestroy {
  isExpanded: boolean = false;

  @Input() formGroup: UntypedFormGroup;
  isOverseasAccountSubscription: Subscription;
  @Input() readOnly: boolean;
  @Input() adminMode: boolean;

  ngOnInit(): void {
    let isOverseasAccount = this.formGroup.get("isOverseasAccount")
    this.isExpanded = isOverseasAccount.value ?? false
    this.isOverseasAccountSubscription = isOverseasAccount.valueChanges.subscribe(v => {
      if (v) {
        this.isExpanded = true
      }
    })
  }

  ngOnDestroy(): void {
    this.isOverseasAccountSubscription.unsubscribe();
  }
}