import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class LoginStatusService {
    private _isLoggedIn: Subject<boolean>;
    constructor() {
        this._isLoggedIn = new BehaviorSubject<boolean>(false);

    }

    get isLoggedIn(): Observable<boolean> {
        return this._isLoggedIn.asObservable();
    }

    setLoggedIn(isLoggedIn: boolean) {
        this._isLoggedIn.next(isLoggedIn);
    }
}