import { AbstractControl, UntypedFormArray, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { bankBranches } from './create-application/form-components/bank-account/bank-branch-register'
import * as O from 'fp-ts/es6/Option'

export function formArrayMinMaxLength(min: number, max: number): ValidatorFn {
  return (c: UntypedFormArray): { [key: string]: any } => {
    let validCount = c.controls.filter(v => O.isSome(O.fromNullable(v.value))).length
    if (validCount >= min && validCount <= max)
      return null;
    return { MinMaxLengthArray: true };
  }
}

export function validateBankAccount(control: AbstractControl): { [key: string]: any } | null {
  const bankNumber: string = control.value;

  if (bankNumber == null) {
    return null
  } else {

    const bankBranchNumber: string = (bankNumber.replace("-", "").substr(0, 6))

    let validBankAccountBranch: string[] = bankBranches

    if (validBankAccountBranch.includes(bankBranchNumber)) {
      return null
    } else {
      return { 'validateBankAccount': true };
    }
  }
};

export function validateClientId(control: AbstractControl): { [key: string]: any } | null {

  const clientId: string = control.value;

  if (clientId == null) {
    return null;
  } else {
    return clientId.length >= 5 && clientId.length <= 7 ? null : { 'validateClientId': true };
  }
}

export const phonePattern: string = "^\\+?\\d{6,15}$"
export const phoneValidator: ValidatorFn = replaceAndPattern(/ |-|\(|\)/g, '', phonePattern);

export const allowedNameCharacters: string = "a-zA-Z0-9āēīōūĀĒĪŌŪàáâäãåèéêëìíîïòóôöõøùúûüÿýñçšžÀÁÂÄÃÅÈÉÊËÌÍÎÏÒÓÔÖÕØÙÚÛÜŸÝÑßÇŒÆŠŽð ,.'\\-";
export const allowedTextCharacters: string = allowedNameCharacters + "_#()/&";
export const allowedExtendedTextCharacters: string = allowedTextCharacters + "€£\n\t|+=!\"\\\\?:;^~<>$%*@[\\]";

export const allowedNameCharactersPattern: string = `^[${allowedNameCharacters}]*`;
export const allowedTextCharactersPattern: string = `^[${allowedTextCharacters}]*`;
export const allowedExtendedTextCharactersPattern: string = `^[${allowedExtendedTextCharacters}]*`;

export const allowedNameCharactersValidator: ValidatorFn = Validators.pattern(allowedNameCharactersPattern);
export const allowedTextCharactersValidator: ValidatorFn = Validators.pattern(allowedTextCharactersPattern);
export const allowedExtendedTextCharactersValidator: ValidatorFn = Validators.pattern(allowedExtendedTextCharactersPattern)

export function replaceAndPattern(replacePattern, replaceWith: string, pattern: string | RegExp): ValidatorFn {

  if (!pattern) return Validators.nullValidator;
  let regex: RegExp;
  let regexStr: string;
  if (typeof pattern === 'string') {
    regexStr = '';

    if (pattern.charAt(0) !== '^') regexStr += '^';

    regexStr += pattern;

    if (pattern.charAt(pattern.length - 1) !== '$') regexStr += '$';

    regex = new RegExp(regexStr);
  } else {
    regexStr = pattern.toString();
    regex = pattern;
  }
  return (control: AbstractControl): ValidationErrors | null => {

    function isEmptyInputValue(value: any): boolean {
      // we don't check for string here so it also works with arrays
      return value == null || value.length === 0;
    };

    if (isEmptyInputValue(control.value)) {
      return null;  // don't validate empty values to allow optional controls
    }
    const toReplace: string = control.value
    const value: string = toReplace.replace(replacePattern, replaceWith);
    return regex.test(value) ? null :
      { 'pattern': { 'requiredPattern': regexStr, 'actualValue': value } };
  };

}
