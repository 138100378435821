import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SupportDetails, phoneValidator, allowedNameCharactersValidator, allowedExtendedTextCharactersValidator } from 'shared';
import { ApplicationService } from '../application.service';

@Component({
  selector: 'support-content.component',
  templateUrl: 'support-content.component.html',
  styleUrls: ['./support.component.css']
})

export class SupportContentComponent {

  supportForm = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required, allowedNameCharactersValidator]),
    phone: new UntypedFormControl(null, [phoneValidator]),
    message: new UntypedFormControl(null, [Validators.required, allowedExtendedTextCharactersValidator]),
  })

  submitting: boolean = false;

  constructor(private snackBar: MatSnackBar, private applicationService: ApplicationService) { }

  onSupportSubmit = new EventEmitter<SupportDetails>();

  submit(): void {
    if (this.supportForm.valid) {
      this.submitting = true;
      this.applicationService.supportApplication(
        this.supportForm.value
      ).subscribe(a => {
        this.submitting = false;
        this.onSupportSubmit.emit(a);
        this.snackBar.open("Your support query has successfully been submitted.", "Dismiss")
      },
        error => {
          this.submitting = false
          this.onSupportSubmit.emit(error)
          this.snackBar.open("An error occurred whilst submitting your query. Please try again later.", "Dismiss")
        })
    }
    else {
      this.snackBar.open("There were errors validating your input. Please check the form and try again.", "Dismiss");
    }
  }

}
